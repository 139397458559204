<template>
  <viewcard--c title="Grupo Estudios" :btnew="btnew">
    <b-row class="mb-1 d-flex justify-content-end">
      <b-col md="5">
        <b-input-group>
          <b-form-input
            placeholder="Pesquise por Nome, Local..."
            autocomplete="off"
            v-model="search"
            @keyup.enter="filter"
          />
          <b-input-group-append>
            <b-button variant="info" @click="filter">
              <feather-icon icon="SearchIcon" class="mr-50"/>
              <span>Pesquisar</span>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <view--c permission="permission.studiogroup.view" :busy="isloading">
      <Table
        :fields="fields"
        :list="!isloading && list[currentePage] ? list[currentePage].itens : []"
        @orderBy="getRecordsOrderBy"
        border="full"
        responsive
      >
        <template #is_third="data" style="width: 1px !important">
          <div class="text-nowrap notify-status">
            <b-avatar
              :variant="data.item.is_third ? 'success' : 'danger'"
              :id="`is-third-${data.item.id}`"
              size="25"
            >
              <feather-icon
                size="16"
                :icon="data.item.is_third ? 'CheckIcon' : 'XIcon'"
              />
            </b-avatar>
          </div>
        </template>
        <template #place="data">
          {{data.item.place.name}}
        </template>
        <template #actions="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`details-studiogroup-row-${data.item.id}`"
              icon="TrelloIcon"
              size="16"
              class="mx-1"
              v-b-tooltip.hover.top="'Detalhes'"
              @click="onClickSelected(data.item)"
            />

            <feather-icon
              :id="`details-studios-row-${data.item.id}`"
              icon="VideoIcon"
              size="16"
              class="mx-1"
              v-b-tooltip.hover.top="'Studios'"
              @click="onClickDetails(data.item)"
            />
          </div>
        </template>
      </Table>

      <b-pagination
        v-model="currentePage"
        @change="getLoadMore"
        :total-rows="rows"
        :per-page="size"
        v-if="rows > 1"
        first-number
        last-number
        align="center"
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </view--c>
  </viewcard--c>
</template>

<script>
import Table from "@/components/Table.vue";
import { VBTooltip } from 'bootstrap-vue'
import _studioGroupService from "@/services/studios-group-service";
export default {
  components: {
    Table,
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data() {
    return {
      btnew: {
        permission: "permission.studiogroup.create",
        to: "/audio-visual/studiogroup/0",
      },
      isloading: false,
      currentePage: 1,
      search: null,
      size: this.$utils.paginationSize(),
      rows: 1,
      fields: [
        { key: "name", label: "Nome", orderBy: { type: "back" } },
        { key: "place", label: "Local", orderBy: { type: "back", customKey: "place.name" } },
        { key: "quantity", label: "Qtd. Estúdios", orderBy: { type: "back" } },
        { key: "is_third", label: "Terceiros", orderBy: { type: "back" } },
        { key: "actions", label: "Ações" },
      ],
      list: [
        {
          page: 0,
          itens: [],
        },
      ],
      orderByParams: {
        search: '',
        ascOrDes: true,
        orderByKey: ''
      },
    };
  },
  created() {
    this.getRecords(this.currentePage);
  },
  methods: {
    getRecords(_page, isOrder = false) {
      this.isloading = true;
      _studioGroupService
        .show(_page, this.orderByParams)
        .then((res) => {
          if (res.content) {
            this.list.push({ page: _page, itens: res.content });
            if (res.content.length > 0) {
              if (isOrder) return;
              this.rows += res.content.length;
              this.currentePage = _page;
            }
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => {
          this.orderList();
          this.isloading = false;
        });
    },
    getLoadMore(_page) {
      if (!this.list.some((s) => s.page === _page)) {
        this.getRecords(_page);
      }
    },
    getRecordsOrderBy(_params) {
      this.orderByParams.ascOrDes = _params.ascOrDes;
      this.orderByParams.orderByKey = _params.orderByKey;
      let oldList = this.list.map((m) => m);
      this.list = [{ page: 0, itens: [] }];
      oldList.forEach((l) => {
        if (l.page < 1) return;
        this.getRecords(l.page, true);
      });
    },
    orderList() {
      this.list.sort((a, b) => {
        if (a.page < b.page) return -1;
        if (a.page > b.page) return 1;
        return 0;
      });
    },
    filter() {
      this.currentePage = 1;
      this.rows = 1;
      this.list = [{ page: 0, itens: [] }];
      this.orderByParams.search = this.search;
      this.getRecords(this.currentePage);
    },
    onClickSelected(record, _) {
      this.$router.push({
        path: `/audio-visual/studiogroup/${record.id}`,
      });
    },
    onClickDetails(record, _) {
      this.$router.push({
        path: `/audio-visual/studios/${record.id}`,
      });
    },
  },
};
</script>